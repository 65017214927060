const fluxdailySettings = {
	title: "The Flux Daily News",
	description: "A subscription-based news service that uses quantum entanglement technology to deliver updates from different timelines. Subscribers can choose to follow potential alternate futures, offering a multidimensional look at what might have been.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(191,112,34)',
	footerBg: "rgb(191,112,34)",
	headerTextColor: "#fff",
	footerTextColor: "#fff",
	style: 'op ed'
};

export default fluxdailySettings;
